<template>
	<div class="pt-5 pl-5 pr-5 pb-1" v-if="canApprove">
		<mw-dialog
			v-model="dialog"
			@submit="submit"
			cancel-btn
			submit-btn
			title="Approve this Assessment"
		>
			<template v-slot:dialog-body>
				To approve this assessment, click 'Submit'
				below. This will finalise your organisation's Digital Maturlty
				Assessment submission and notify the assessment lead of your approval.
			</template>
			<template v-slot:button>
				<v-btn color="success" @click="dialog = true" block>Approve</v-btn>
			</template>
		</mw-dialog>
	</div>
</template>

<script>
	import MwDialog from "@c/ui/MwDialog";
	export default {
		name: "Approve",
		data: () => {
			return {
				dialog: null
			};
		},
		components: {
			MwDialog
		},
		computed: {
			assessment() {
				return this.$store.state.assessment.data;
			},
			canApprove() {
				return this.assessment.stage == "submitted";
			}
		},
		methods: {
			submit() {
				this.dialog = false;
				this.$store.dispatch("assessment/patch", {
					stage: "approved",
				});
			}
		}
	};
</script>
