<template>
	<v-sheet flat>
		<v-card flat class="mt-4 mb-4" color="lightest">
			<v-card-title>{{ section.name }}</v-card-title>
			<v-card-text v-html="section.introduction"> </v-card-text>
		</v-card>
		<h3 v-if="response.multiple">
			{{ responseName }}
		</h3>
		<v-sheet v-if="visibleQuestions.length">
			<questions
				:disabled="true"
				:ids="visibleQuestions"
                :poll="false"
                :staff-survey="false"
				v-model="response.data"
			></questions>
		</v-sheet>
		<p v-else>{{ $t("messages.no_questions_found") }}</p>
	</v-sheet>
</template>

<script>
import Questions from "@c/survey/render/Questions.vue";
export default {
	name: "SurveySection",
	data: () => {
		return {
			error: false,

		};
	},
	components: {
		Questions,
	},
	computed: {
		current() {
			return this.$store.state.current;
		},
		assessment() {
			return this.$store.state.assessment.data;
		},
		organisation() {
			return this.$store.state.organisation.data;
		},
		isLead() {
			return this.$store.getters.isLead;
		},
		isDelegated() {
			return this.response.status == "delegated";
		},
		isComplete() {
			return this.response.status == "complete";
		},
		section() {
			return this.$store.state.sections.data[this.current.section];
		},
		questions() {
			return this.$store.state.questions.data;
		},
		questionTemplates() {
			return this.$store.state.questionTemplates.data;
		},
		categoryOptions() {
			return this.$store.state.categoryOptions.data || {};
		},
		response() {
			return this.$store.state.sectionResponses.data[this.current.response];
		},
		sectionQuestions() {
			return this.section.questions || [];
		},
		responseName() {
			var rule = this.$store.getters.currentMultipleRule;
			if (rule) {
				if (rule.name) {
					return rule.name;
				} else {
					return this.categoryOptions[rule.value].name || "";
				}
			}
			return "";
		},
		visibleQuestions() {
			const self = this;
			let questions = self.sectionQuestions.filter((q) => {
				let y = self.testCategoryRules(q);
				return (
					y &&
					self.mwsurveyutils.isVisible(q, self.response)
				);
			});
			questions = self.mwsurveyutils.hideAnswered(questions, self.response);
			return questions;
		},
	},
	methods: {
		testCategoryRules(id) {
			const self = this;
			let question = self.questions[id];
			let passed = true;
			if (self.mwsurveyutils.questionHasConditions(question)) {
				passed = self.mwsurveyutils.filterQuestion(question.rules, {response: self.response, organisation: self.organisation} );
			}
			return passed;
		},
	},
};
</script>
