<template>
	<v-list-item v-if="response" @click="click" :class="{active: active}" :three-line="response.multiple">
		<v-list-item-avatar color="primary" class="white--text"><slot name="avatar"></slot></v-list-item-avatar>
		<v-list-item-content>
			<v-list-item-title>{{ section.name }}</v-list-item-title>
			<v-list-item-subtitle v-if="response.multiple">{{ category.name }}</v-list-item-subtitle>
			<v-list-item-subtitle>
				<theme-chip :id="section.id"></theme-chip>
			</v-list-item-subtitle>
		</v-list-item-content>
	</v-list-item>
</template>
<style lang="less">
.active {
	background: #e4effa !important;
}
</style>
<script>
import ThemeChip from "@c/navigation/assessment/sections/ThemeChip";
export default {
	name: "SectionNav",
	props: {
		id: { type: String }, // the id of the response

	},
	computed: {
		current(){
			return this.$store.state.current;
		},
		active(){
			return this.current.response == this.id;
		},
		section() {
			return this.$store.state.sections.data[this.response.section];
		},
		response() {
			return this.$store.state.sectionResponses.data[this.id];
		},
		rule(){
			return this.$store.getters.getRuleForResponse(this.id);
		},
		category(){
			if( this.rule ){			
				return this.$store.state.categoryOptions.data[this.rule.value];
			}
			return null;
		}
	},
	data() {
		return {};
	},
	components: {
		ThemeChip
	},
	methods: {
		click() {
			this.$store.commit("GO_TO", {
				section: this.section.id,
				response: this.id,
			});
		
		},
	},
};
</script>
