<template>
	<span>
		<v-tooltip top v-if="prev">
			<template v-slot:activator="{ on }">
				<v-btn v-on="on" icon outlined class="mr-2" @click="goTo(prev)" :class="{'mb-2': collapsedMobile}">
					<v-icon>mdi-chevron-left</v-icon>
				</v-btn>
			</template>
			Previous Section
		</v-tooltip>
		<v-tooltip top v-if="next">
			<template v-slot:activator="{ on }">
				<v-btn icon outlined class="mr-2"  v-on="on" @click="goTo(next)">
					<v-icon>mdi-chevron-right</v-icon>
				</v-btn>
			</template>
			Next Section
		</v-tooltip>
	</span>
</template>

<script>
export default {
	name: "DirectionButtons",
	props: {
		mini: {type: Boolean}
	},
	computed: {
		order() {
			return this.$store.getters.order;
		},
		current() {
			return this.$store.state.current;
		},
		currentIndex() {
			var current = JSON.stringify(this.current);
			return this.order.map((b) => JSON.stringify(b)).findIndex((item) => item == current);
		},
		prev() {
			var i = this.currentIndex - 1;
			return this.order[i] || false;
		},
		next() {
			var i = this.currentIndex + 1;
			return this.order[i] || false;
		},
		collapsedMobile(){
			return this.isMobile && this.mini
		}
	},
	inject: ["isMobile"],
	methods: {
		goTo(to) {
			this.$store.commit("GO_TO", to);
		},
	},
};
</script>
