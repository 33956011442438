<template>
	<v-navigation-drawer
		v-model="drawer"
		:mini-variant="mini"
		permanent
		app
		right
		style="z-index: 101"
		touchless
		width="350px"
	>
		<template v-slot:prepend v-if="!mini">
			<approve></approve>
			<reject></reject>
		</template>
		<v-list dense>
			<template v-if="!mini">
				<section-navigation-item
					v-for="(section, i) in order"
					:key="i"
					:id="section.response"
				>
					<template v-slot:avatar>{{ i + 1 }}</template>
				</section-navigation-item>
			</template>
		</v-list>
		<template v-slot:append>
			<v-divider class="mt-1 mb-3"></v-divider>
			<!-- ///Forward and back buttons here -->
			<v-list dense class="pl-1">
				<v-list-item>
					<v-tooltip top>
						<template v-slot:activator="{on}">
							<v-btn v-on="on" icon @click="$store.commit('CLEAR_CURRENT')" outlined color="primary"  v-if="!mini" class="mr-2">
								<v-icon>mdi-home</v-icon>
							</v-btn>
						</template>
					Home
					</v-tooltip>
					<direction-buttons></direction-buttons>
					<export-whole-assessment
					></export-whole-assessment>
				</v-list-item>
				<nav-item
					@click="mini = !mini"
					:text="$t('buttons.collapse')"
					icon="mdi-menu"
				></nav-item>
			</v-list>
		</template>
	</v-navigation-drawer>
</template>
<style lang="less">
.v-list-item {
	padding: 0px 10px !important;
}
</style>
<script>
import DirectionButtons from "@c/navigation/assessment/DirectionButtons";
import NavItem from "@c/navigation/Item";
import SectionNavigationItem from "@c/approver/SectionNavigationItem";
import Approve from "@c/approver/Approve";
import Reject from "@c/approver/Reject";
import ExportWholeAssessment from '@c/downloads/ExportWholeAssessment.vue';
export default {
	name: "ApprovalNavigation",
	data() {
		return {
			drawer: true,
			mini: false,
		};
	},
	computed: {
		organisation() {
			return this.$store.state.organisation.data;
		},
		order() {
			return this.$store.getters.approverOrder;
		},
	},
	components: {
		NavItem,
		SectionNavigationItem,
		DirectionButtons,
		ExportWholeAssessment,
		Approve,
		Reject
	},

	inject: ["isMobile"],
	created() {
		if (this.isMobile) {
			this.mini = true;
		}
	},
};
</script>
