<template>
	<div class="pl-5 pr-5" v-if="canApprove">
		<mw-dialog
			v-model="dialog"
			@submit="submit"
			cancel-btn
			submit-btn
			title="Reject this Assessment"
		>
			<template v-slot:dialog-body>
				To reject this assessment, click 'Submit' below. The assessment lead
				will automatically receive a notification that you have rejected this
				assessment, which will include any notes you enter in the notes field
				below. The assessment lead will be able to edit responses to questions
				before resubmitting the assessment for your approval.
				<v-textarea
					label="Your comments on this assessment"
					v-model="assessment.approver_notes"
				></v-textarea>
			</template>
			<template v-slot:button>
				<v-btn color="secondary" @click="dialog = true" block>Reject</v-btn>
			</template>
		</mw-dialog>
	</div>
</template>

<script>
	import MwDialog from "@c/ui/MwDialog";
	export default {
		name: "Approve",
		data: () => {
			return {
				dialog: null
			};
		},
		components: {
			MwDialog
		},
		computed: {
			assessment() {
				return this.$store.state.assessment.data;
			},
			canApprove() {
				return this.assessment.stage == "submitted";
			},
			order() {
				return this.$store.getters.order;
			}
		},
		methods: {
			submit() {
				const self = this;
				self.dialog = false;
				self.order.forEach((item) => {
					self.$store.dispatch("sectionResponses/patch", {
						id: item.response,
						status: "in_progress"
					});
				});
				self.$store.dispatch("assessment/patch", {
					stage: "in_progress",
					approver_notes: self.assessment.approver_notes || ""
				});
			}
		}
	};
</script>
