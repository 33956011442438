<template>
	<v-sheet v-if="canApprove">
		<template v-if="!current.response">
			
			<p>Welcome to the Scottish Government Health & Care Digital Maturity Assessment Tool! </p>
			<p>You’ve reached this page because you are the nominated approver for {{organisation.name}}’s Assessment. Once your Assessment Lead has submitted the Assessment for your approval, you can view the answers provided for your organization before rendering your approval using the green button at the top right edge of this window. </p>
			<p>The current status for your assessment is shown below. </p>
			<v-alert type="info" color="primary" text v-if="assessment.stage == 'in_progress'">
				<h3>Your assessment is still in progress</h3>
				<p>You can review your organisation's progress</p>
			</v-alert>
			<v-alert type="info" color="primary" text v-if="assessment.stage == 'approved'">
				<h3>Your assessment has already been approved</h3>
				<p>You can review your organisation's submission by using the navigation on the right.</p>
			</v-alert>
			<v-alert type="info" color="primary" text v-if="assessment.stage == 'submitted'">
				<h3>The assessment has been submitted for your approval</h3>
				<p>You can review your organisation's submission by using the navigation on the right.</p>
			</v-alert>
			<p>More information is available via the Help section. If you require any further assistance please reach out to our support team via the chat.</p>
		</template>
		<render-section v-else></render-section>
		<approval-naviation></approval-naviation>
	</v-sheet>
</template>

<script>
import ApprovalNaviation from "@c/approver/Navigation";
import RenderSection from "@c/approver/RenderSection";
export default {
	name: "Approve",
	components: {
		ApprovalNaviation,
		RenderSection
	},
	computed: {
		current(){
			return this.$store.state.current;
		},
		user() {
			return this.$store.state.auth.data;
		},
		organisation() {
			return this.$store.state.organisation.data;
		},
		assessment() {
			return this.$store.state.assessment.data;
		},
		isApprover() {
			return this.$store.getters.isApprover;
		},
		canApprove() {
			return (
				this.isApprover &&
				this.user.organisations.includes(this.organisation.id)
			);
		},
	},
	watch: {
		assessment: {
			immediate: true,
			handler(value) {
				if (value) {
					this.$store.dispatch("assessment/fetchData");
				}
			},
		},
	},
};
</script>
