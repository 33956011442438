<template>
	<vue-excel-xlsx :columns="headers" :data="items" :filename="filename">
		<v-btn icon outlined
			><v-icon>mdi-download</v-icon></v-btn
		>
	</vue-excel-xlsx>
</template>

<style lang="less"></style>

<script type="text/javascript">
	import VueExcelXlsx from "vue-excel-xlsx";
	import Vue from "vue";
	Vue.use(VueExcelXlsx);

	export default {
		name: "ExportWholeAssessment",
		props: {},
		data: () => {
			return {
				headers: [
					{field: "section", label: "Section"},
					{field: "question", label: "Question"},
					{field: "subquestion", label: "Subquestion"},
					{field: "answer", label: "Answer"}
				]
			};
		},
		computed: {
            assessment() {
                return this.$store.state.assessment.data;
            },
            organisation() {
                return this.$store.state.organisation.data;
            },
            filename(){
                return this.organisation.name;
            },
			responses() {
				return this.$store.state.sectionResponses.data || {};
			},
			sections() {
				return this.$store.state.sections.data || {};
			},
			order() {
				return this.$store.getters.order;
			},
			items() {
				const self = this;
				let items = [];
				self.order.forEach(({response}) => {
					response = self.responses[response];
					if (!response) {
						return;
					}
					const section = self.sections[response.section] ? self.sections[response.section].name : '';
					let rows = self.mwsurveyutils.processResponse(response) || [];
					rows = rows.filter((row) =>
						self.mwsurveyutils.isVisible(row.question, response)
					);
					rows.forEach((row) => {
						let question = self.$store.getters["questions/getQuestionTexts"](
							row.question
						);
						items.push({
							section,
							question: question.question_text,
							subquestion: question.subquestion_text,
							answer: row.processed_answer
						});
					});
				});
				return items;
			}
		}
	};
	//
</script>
"
